<div class="modal-header py-0">
    <h2 class=""> Register now </h2>
    <button type="button" class="close mt-0" aria-level="Close" (click)="dismissModal()">
        <span aria-hidden="true" class="icon ion-md-close close"></span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="guestForm">
        <!-- Last name -->
        <div class="form-group required mb-4">
        <label for="lastname"> Last name</label>

        <span class="text-center">
            <input
            type="text"
            class="form-control"
            formControlName="lastname"
            [ngClass]="{'is-invalid': guestForm.get('lastname').hasError('required') && (guestForm.get('lastname').touched || submitted)}"
            />
        </span>
        <span *ngIf="guestForm.get('lastname').hasError('required') && (guestForm.get('lastname').touched || submitted)" class="text-danger"> <small>This field is required</small> </span>
        </div>

        <!-- Firstname -->
        <div class="mb-4">
        <span class="required">
            <label for="firstname"> Firstname</label>
        </span>

        <span class="text-center">
            <input
            type="text"
            class="form-control"
            formControlName="firstname"
            [ngClass]="{'is-invalid': guestForm.get('firstname').hasError('required') && (guestForm.get('firstname').touched || submitted)}"
            />
            <span *ngIf="guestForm.get('firstname').hasError('required') && (guestForm.get('firstname').touched || submitted)" class="text-danger"> <small>This field is required</small> </span>
        </span>
        </div>

        <!-- Adress email -->
        <div class="form-group mb-4">
        <span class="required">
            <label for="email"> Adress email</label>
        </span>

        <div>
            <input                  
            type="text"
            class="form-control"
            formControlName="email"
            [ngClass]="{'is-invalid': guestForm.get('email').hasError('required') && (guestForm.get('email').touched || submitted)}"
            />
            <div *ngIf="guestForm.get('email').hasError('required') && (guestForm.get('email').touched || submitted)" class="text-danger"> <small>This field is required</small>  </div>
            <div>
                <small class="text-info ">
                    You will receive a link on this email to confirm your
                    participation
                </small>
            </div>
        </div>
        </div>

        <!-- Phone number -->
        <div class="form-group mb-4">
            <label for="phoneNr"> Phone number</label>

            <span class="text-center">
                <input
                class="form-control"
                type="text"
                formControlName="phoneNr"
                />
            </span>
        </div>

        <!-- events -->
        <div>
            <div class="required">
                <label class="mb-0"> Events</label>
                <div>
                    <small class="text-info">
                        Select the events you want to come to.
                    </small>
                </div>
            </div>
            <div class="check-input mt-3">
                <div
                *ngFor="let choice of events; let i = index"
                class="check-input text-nowrap"
                >
                <input
                    class="mt-1"
                    type="checkbox"
                    [value]="choice.value"
                    (change)="onCheckChange($event)"
                />
                <label class="ml-3">
                    <small>
                    {{ choice.description }}
                    </small>
                </label>
                </div>         
            </div>
            <span *ngIf="guestForm.value.selectedEvents.length <= 0 &&  submitted" class="text-danger"> <small>You must select at least one event </small> </span>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" (click)="dismissModal()"> Cancel </button>
    <button class="btn btn-secondary" type="submit" (click)="onSubmit()">
        Send
    </button>
</div>
