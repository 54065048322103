import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models/user/user';
import { WeddingEvents } from 'src/app/shared/models/wedding_events';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-participate',
  templateUrl: './participate.component.html',
  styleUrls: ['./participate.component.scss']
})
export class ParticipateComponent implements OnInit {
  events = [
    { description: 'Traditional marriage, On 08.05.2021 at 06pm', value: WeddingEvents.DOWRY },
    { description: 'Civil marriage, On 14.05.2021 at 09am', value: WeddingEvents.TOWN_HALL },
    { description: 'Church, On 14.05.2021 at 03pm', value: WeddingEvents.CHURCH },
    { description: 'Wedding Night, On 14.05.2021 at 07pm', value: WeddingEvents.PARTY },
  ];
  guestForm: FormGroup;
  submitted = false;

  constructor( 
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private userService: UserService,
    private activeModal: NgbActiveModal
    ) {
    this.guestForm = this.formBuilder.group({
      lastname: [null, Validators.required],
      firstname: [null, Validators.required],
      email: [null, Validators.required],
      phoneNr: [],
      selectedEvents: new FormArray([]),
    });
   }

  ngOnInit(): void {
  }

  onCheckChange(event): void {
    
    const formArray: FormArray = this.guestForm.get(
      'selectedEvents'
    ) as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }

  }

  onSubmit(): void {
    this.submitted = true;
    if (this.guestForm.valid && this.guestForm.value.selectedEvents.length > 0) {
      const user = new User();
      user.lastname = this.guestForm.value.lastname;
      user.firstname = this.guestForm.value.firstname;
      user.email = this.guestForm.value.email;
      user.phoneNr = this.guestForm.value.phoneNr;
      user.selectedEvents = this.guestForm.value.selectedEvents;
      this.userService.createUser(user).subscribe(() => {
        this.toastrService.success('You received a Email at the adress you entered. Please confirm you adress');
        this.closeModal();
      },
      () => {
        this.toastrService.error('An error occured');
      });
    }
  }

  closeModal(){
    this.activeModal.close();
  }

  dismissModal(){
    this.activeModal.dismiss();
  }

}
