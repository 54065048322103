import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ParticipateComponent } from 'src/app/modals/participate/participate/participate.component';
import { AuthUser } from 'src/app/shared/models/user/auth-user';
import { User } from 'src/app/shared/models/user/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('paypalRef', {static: false}) paypalRef: ElementRef;

  paypalPaid = false;

  countDate = new Date('2021-05-14T00:00:00').getTime();
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  guestForm: FormGroup;
  submitted = false;
  loading = true;

  bouncing = true;

  amount: number;

  wantToSentPaypal = false;

  addScript = false;

  paypalConfig = {
    env: 'production', //'production' or 'sandbox'
    client: {
      sandbox: 'AT5DUkNTwdRzg1GRg2-77fPaeKkKPcRYqf7pBFrUpI1aYNvK22eSx7I6g1Sjlzc5XIaKINqFRn7s_IBt',
      production: 'AUmikrP353cuXEFZvj5BPWpVnZqQD-tsJkTBzSra8RUgydhvq8bYDKutX7U3upUOu2WrVTIAycKnHjYT'
    },
    style: {
      size: 'responsive',
      color: 'gold',
      shape: 'pill',
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            {
              description: 'Joelle and Ghandi Wedding',
              amount: {total: this.amount, currency: 'EUR'}
            }
          ]
        }
      });
    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then(() => {
        this.wantToSentPaypal = false;
        this.amount = null;
        this.toastrService.success('Thank you for your paypal!');
        // do something wen payment is executed
      }).catch(() => {
        this.wantToSentPaypal = false;
        this.amount = null;
        this.toastrService.error('Sorry an error occur!');
      });
    },
    onCancel: function(data, actions) {
      /*
       * Buyer cancelled the payment
       */
      this.wantToSentPaypal = false;
        this.amount = null;
        this.toastrService.error('Payment cancelled!');
    },
    onError: function(err) {
      this.wantToSentPaypal = false;
        this.amount = null;
        this.toastrService.error('An error occur!');
    }
  };


  private modalConfig: NgbModalOptions;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private modalSerivice: NgbModal
  ) {
    this.modalConfig = {size: 'lg'};
    setInterval(() => {
      this.newDate();
    }, 1000);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const user = new User();
      user.id = params['id'];
      user.emailVerifyToken = params['token'];
      if (user.id || user.emailVerifyToken) {
        this.authService.login({username: 'joelleandghandi', password: 'pw'}).subscribe((authUser: AuthUser) => {
          this.authService.doLogin(authUser);
          this.userService.confirmUserEmail(user).subscribe(() => {
            this.toastrService.success('Email successfully confirmed!');
            this.loading = false;
          },
          () => {
            this.toastrService.error('User not found');
            this.loading = false;
          });
        });
      } else {
        this.loading = false;
      }
    });

  }

  checkAmount() {
    if(this.amount && this.addScript){
      this.addScript = false;
    }
    if (!this.addScript && this.amount) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
      });
    }
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      const scriptTagElement = document.createElement('script');
      scriptTagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scriptTagElement.onload = resolve;
      document.body.appendChild(scriptTagElement);
    });
  }
  praparePayment() {
    this.wantToSentPaypal = true;
  }  
  cancelPayment() {
    this.wantToSentPaypal = false;
    this.amount = undefined;
  }
  // doPaypalPayment(){
  //   window.paypal.Buttons({
  //     createOrder: async (data, actions) => {
  //       return await actions.order.create({
  //         purchase_units:[
  //           {
  //             description: 'Joelle and Ghandi Wedding',
  //             amount: {
  //               value: this.amount,
  //               currency_code: "EUR"
  //             }
  //           }
  //         ]
  //       });
  //     },
  //     onApprove: async (data, actions) => {
  //       const order = await actions.order.capture();
  //       this.paypalPaid = true;
  //       this.wantToSentPaypal = false;
  //       this.amount = undefined;
  //     },
  //     onError: err => {
  //       this.wantToSentPaypal = false;
  //       this.amount = undefined;
  //     }
  //   }).render(this.paypalRef.nativeElement);
  // }

  newDate(): void {
    const now = new Date().getTime();
    const gap = this.countDate - now;
    const seconds = 1000;
    const minutes = seconds * 60;
    const hours = minutes * 60;
    const days = hours * 24;
    this.days = Number(Math.floor(gap / days));
    this.hours = Math.floor((gap % days) / hours);
    this.minutes = Math.floor((gap % hours) / minutes);
    this.seconds = Math.floor((gap % minutes) / seconds);
  }

  openParticipateModal() {
    this.bouncing = false;
    const modalRef: NgbModalRef = this.modalSerivice.open(ParticipateComponent, this.modalConfig);
    modalRef.result.then(() => {
    }).catch(() => {this.bouncing = true;});
  }
}
