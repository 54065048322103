<div class="content-size mt-5 page-height bg-color">
    <div class="text-center">
        <h1 class="pt-4 tangerie"> Guest Book </h1>
    </div>
    <div class="text-center my-5 custom-with mx-auto">
        <p class=""> 
            Few words, a short message or a whole speech, the choice is yours! May it be a great and wonderful memory for us.</p>
    </div>

    <div class="my-5 text-center">
        <button *ngIf="!showModal && !gbWritten" class="btn btn-primary" (click)="toggleModalForm()" > » Write a new comment </button>
    </div>

    <div *ngIf="showModal" class="modal-content custom-with mx-auto mt-3 mb-5">
        <div class="modal-header pt-3 pb-1">
            <h5 class="py-1"> Write a comment </h5>
            <button type="button" class="close" (click)="toggleModalForm()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="gbForm">
                <div class="form-group row required px-3">
                    <label class=""> Name </label>
                    <input
                    [ngClass]="{'is-invalid': gbForm.get('autorName').hasError('required') && (gbForm.get('autorName').touched || submitted)}"
                    type="text" class="form-control" formControlName="autorName">
                    <div *ngIf="gbForm.get('autorName').hasError('required') && (gbForm.get('autorName').touched || submitted)" class="text-danger"> <small>This field is required</small>  </div>
                </div>
                <div class="form-group row required px-3">
                    <label> Email </label>
                    <input
                    [ngClass]="{'is-invalid': gbForm.get('autorEmail').hasError('required') && (gbForm.get('autorEmail').touched || submitted)}"
                    type="text" class="form-control" formControlName="autorEmail">
                    <div *ngIf="gbForm.get('autorEmail').hasError('required') && (gbForm.get('autorEmail').touched || submitted)" class="text-danger"> <small>This field is required</small>  </div>
                </div>
                <div class="form-group row required px-3">
                    <label> message </label>
                    <!-- <button (click)="isEmojiPickerVisible = !isEmojiPickerVisible">😀</button>
                    <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" title="Choose your emoji"></emoji-mart> -->
                    <textarea
                    [ngClass]="{'is-invalid': gbForm.get('content').hasError('required') && (gbForm.get('content').touched || submitted)}"
                    type="text" class="form-control" formControlName="content">
                 </textarea>
                    <div *ngIf="gbForm.get('content').hasError('required') && (gbForm.get('content').touched || submitted)" class="text-danger"> <small>This field is required</small>  </div>
                </div>
            </form>
        </div>
        <div class="modal-footer py-1">
            <button type="button" class="btn btn-outline-secondary btn-sm" (click)="toggleModalForm()">CANCEL</button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="addGuestbook()">SAVE </button>
        </div>
    </div>

    <div *ngIf="guestBooks.length > 0" class="text-left">
        <article class="gb-entry mb-3" *ngFor="let gb of guestBooks">
            <div class="pl-3 pb-1 pr-3 pt-1 card gb-color">
                <div class="row">
                    <span class="ml-3 mr-2" style="color: black;">
                        <strong> {{ gb.authorName }} </strong>
                    </span>
                    <small class="text-nowrap gb-datetime mt-1">
                        {{gb.createdAt | date: 'medium'}}
                    </small>
                    <span *ngIf="authUser.role === 'ADMIN'" class="ml-auto mr-3">
                        <i placement="top" ngbTooltip="delete the comment!" class="icon ion-md-trash pull-right" (click)="deleteGb(gb)"></i>
                    </span>
                </div>
                <div class="gb-entry-content pl-2 py-2" style="color: black;">
                    <span>{{gb.content}}</span>
                </div>
                <div class="row">
                    <span  class="ml-auto mr-3">
                        <i *ngIf="gb.likes > 0" placement="top" ngbTooltip="Like the comment!"
                        class="icon ion-md-heart" (click)="toggleLikeGb(gb)"> </i>
                        <i *ngIf="gb.likes == 0" placement="top" ngbTooltip="Like the comment!" class="icon ion-md-heart-empty" (click)="toggleLikeGb(gb)"></i>
                        <small style="color: black;"> {{gb.likes}} likes </small>
                    </span>
                </div>
            </div>
            <!-- <div class="row gb-autor-info">
                <span class="mr-1">
                    <strong> {{ gb.authorName }} </strong>
                </span>
                <small class=" text-nowrap gb-datetime">
                    {{gb.createdAt | date: 'medium'}}
                </small>
                <span class="mr-auto">
                    <i class="icon ion-md-trash pull-right" (click)="deleteGb(gb)"></i>
                </span>
            </div>
            <div class="gb-entry-content pl-2 pt-1">
                <span>{{gb.content}}</span>
            </div>
            <div class="pl-auto pt-1">
                <span >
                    <i placement="top" ngbTooltip="Like the comment!"
                    class="icon ion-md-heart" (click)="toggleLikeGb(gb)"> </i>
                    <small> {{gb.likes}} likes </small>
                </span>
                <span *ngIf="gb.likes == 0">
                    <i class="icon ion-md-heart-empty" (click)="toggleLikeGb(gb)"></i>
                </span>
            </div> -->
        </article>
    </div>

    <div class="mt-5 text-center" *ngIf="guestBooks.length <= 0">
        <span> Guest Book ist empty. Be the first and write something! </span>
    </div>
</div>
 