import { Component, OnInit, ɵangular_packages_core_core_bg } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models/user/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { GuestBook } from '../../shared/models/guest-book/guest-book';
import { GuestBookService } from '../../shared/services/guest-book.service';

@Component({
  selector: 'app-guest-book',
  templateUrl: './guest-book.component.html',
  styleUrls: ['./guest-book.component.scss']
})
export class GuestBookComponent implements OnInit {
  guestBooks: GuestBook[] = [];
  submitted = false;
  gbForm: FormGroup;
  showModal = false;
  gbWritten = false;
  loading = true;
  authUser: User;
  // public isEmojiPickerVisible: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private guestBookService: GuestBookService,
    private toastrService: ToastrService,
    private authService: AuthService
    ) {
    this.gbForm = this.formBuilder.group({
      autorName: [null, Validators.required],
      autorEmail: [null, Validators.required],
      content: [null, Validators.required]
    });
   }


  ngOnInit(): void {
    this.authUser = this.authService.getAuthUser();
    this.loadGuestBooks();
  }

//   public addEmoji(event) {
//     this.gbForm.value.content = `${this.gbForm.value.content}${event.emoji.native}`;
//     this.isEmojiPickerVisible = false;
//  }

  loadGuestBooks(): void {
    this.loading = true;
    this.guestBookService.getGuestbooks().subscribe((gbs: GuestBook[]) => {
      this.guestBooks = gbs;
      this.loading = false;
    });
  }

  toggleModalForm(): void {
    this.showModal = !this.showModal;
  }

  addGuestbook(): void {
    this.submitted = true;
    if (this.gbForm.valid) {
      this.loading = true;
      const gb = new GuestBook();
      gb.autorName = this.gbForm.value.autorName;
      gb.autorEmail = this.gbForm.value.autorEmail;
      gb.content = this.gbForm.value.content;
      gb.accountId = 1;
      console.log(gb);
      this.guestBookService.createGuestBook(gb).subscribe(() => {
        this.toastrService.success(`Thank you ${gb.autorName} for your message`);
        this.loadGuestBooks();
      });
      this.showModal = false;
      this.gbWritten = true;
    }
  }

  toggleLikeGb(gb: GuestBook): void {
    this.guestBookService.likeGuestbook(gb).subscribe((result) => {
      gb.likes = gb.likes + 1;
      gb = result;
      // gb.likes = result.likes;
      console.log(gb);
    });
  }

  deleteGb(gb: GuestBook){
      this.guestBookService.blockGuestbook(gb).subscribe(() => {
        this.loadGuestBooks();
      });
  }
}
