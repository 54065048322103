import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GuestBook } from '../models/guest-book/guest-book';
import { RestConfig } from './rest.config'

@Injectable()
export class GuestBookService {

    constructor(private httpClient: HttpClient){

    }

    getGuestbooks(): Observable<GuestBook[]> {
       return this.httpClient.get<GuestBook[]>(`${RestConfig.apiUrl}/guestbooks`);
    }

    createGuestBook(guestBook: GuestBook): Observable<GuestBook>{
        return this.httpClient.post<GuestBook>(`${RestConfig.apiUrl}/guestbooks`, guestBook);
    }

    likeGuestbook(guestBook: GuestBook): Observable<GuestBook>{
        return this.httpClient.put<GuestBook>(`${RestConfig.apiUrl}/guestbooks/${guestBook.id}/like`, guestBook);
    }

    blockGuestbook(guestBook: GuestBook): Observable<GuestBook>{
        return this.httpClient.put<GuestBook>(`${RestConfig.apiUrl}/guestbooks/${guestBook.id}/block`, guestBook);
    }

}