import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthUser } from 'src/app/shared/models/user/auth-user';
import { User } from 'src/app/shared/models/user/user';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted = false;
  loginForm: FormGroup;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router
  ) { 
    this.loginForm = this.formBuilder.group({
      username: ['joelleandghandi'],
      password: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    if(this.authService.isLoggedIn()){
      this.router.navigate(['/home']);
    }
  }

  login(){
    this.submitted = true;
    if(this.loginForm.valid) {
      const loginData = {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password
      }
      this.authService.login(loginData).subscribe((authUser: AuthUser) => {
        this.authService.doLogin(authUser);
        this.toastrService.success('Login successful!');
        this.router.navigate(['/home']);
      },
      (error) => {
        this.toastrService.error('Login failed');
      });
      return true;
    }
    return false;
  }



}
