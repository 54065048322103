<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
  <a class="navbar-brand" href="#">
    <h4 class="mt-n13px"><strong> Joelle & Ghandi </strong></h4>
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="toggleCollapsed()"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    class="collapse navbar-collapse"
    id="navbarSupportedContent"
    [ngClass]="{ collapse: isCollapsed, 'navbar-collapse': true }"
  >
    <ul class="navbar-nav ml-auto mr-5">
      <li class="nav-item active" (click)="toggleCollapsed()">
        <a class="nav-link mr-3" routerLink="/home" routerLinkActive="active">
          Home <span class="sr-only">(current)</span>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link mr-3" href="#eventsDetails">Events details</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mr-3" href="#aboutUs"> About us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mr-3" href="#gifts"> Gifts </a>
      </li> -->
      <li class="nav-item" (click)="toggleCollapsed()">
        <a class="nav-link mr-3" routerLink="/guest-book" routerLinkActive="active"> Guest Book </a>
      </li>
      <li class="nav-item" (click)="toggleCollapsed()">
        <a class="nav-link mr-3" routerLink="/media" routerLinkActive="active"> Videos/Pictures </a>
      </li>
      <li *ngIf="isLoggedIn && authUser?.role ==='ADMIN'" class="nav-item" (click)="toggleCollapsed()">
        <a class="nav-link mr-3" routerLink="/admin" routerLinkActive="active"> administration </a>
      </li>
      <li  class="nav-item" (click)="toggleCollapsed()">
        <a *ngIf="!isLoggedIn" routerLink="/login" routerLinkActive="active" class="nav-link"> <i class="icon ion-md-person"></i> Login </a>
        <a *ngIf="isLoggedIn" routerLink="/home" class="nav-link" (click)="logout()"> <i class="icon ion-md-person"></i> Logout </a>
      </li>
    </ul>
  </div>
</nav>

<router-outlet></router-outlet>

<div *ngIf="isLoggedIn" class="audio bg-color">
  <audio controls autoplay loop style="height: 23px; z-index: 2;" id="audio">
    <source src="assets/music/amir_longtemps.mp3" type="audio/mp3">
  </audio>
</div>

<footer>
  <div class="text-center" style="color: white">
    <small>
      Copyright © 2021 Kouokam <br/>
      Design with ❤️ by Kouokam
      <!-- Design with ♥❤️ by Kouokam -->
    </small>
  </div>
</footer>
