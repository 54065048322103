import { UserStatus } from './user_status';
import { UserRole } from './user_role';

export class User {
  public id: number;

  public createdAt: Date;

  public updatedAt: Date;

  public accountId: number;

  public lastname: string;

  public firstname: string;

  public email: string;

  public phoneNr: string;

  // Token for verifying the email adresse
  public emailVerifyToken: string;

  public selectedEvents: string[];

  // ADMIN, GUEST
  public role: UserRole;

  // PENDING, INVITED, REJECTED
  public status: UserStatus;
}
