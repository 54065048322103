import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from './shared/services/user.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { GuestBookService } from './shared/services/guest-book.service';
import { AuthService } from './shared/services/auth.service';
import { LoginComponent } from './components/login/login.component';
import { GuestBookComponent } from './components/guest-book/guest-book.component';
import { AdminComponent } from './components/admin/admin.component';
import { MediaComponent } from './components/media/media.component';
import { HomeComponent } from './components/home/home.component';
import { ParticipateComponent } from './modals/participate/participate/participate.component';
import { DefaultHttpInterceptor } from './shared/http/default-http.interceptor';
import { PickerModule } from '@ctrl/ngx-emoji-mart';


@NgModule({
  declarations: [AppComponent, HomeComponent, LoginComponent, GuestBookComponent, AdminComponent, MediaComponent, ParticipateComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    BrowserAnimationsModule,
    PickerModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: DefaultHttpInterceptor, multi: true},
    UserService, GuestBookService, AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
