import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs/operators";
import { AuthService } from '../services/auth.service';

@Injectable()
export class DefaultHttpInterceptor implements HttpInterceptor {
    private subdomain: string;

    constructor(
        private router: Router,
        private toastrService: ToastrService,
        private injector: Injector
    )
    {
        const splitedDomain = window.location.host.split('.');
        if(splitedDomain.length >= 2) {
            this.subdomain = splitedDomain[0];
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authService = this.injector.get(AuthService);
        const newHeaders: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${authService.getToken()}`,
            Account: this.subdomain || 'joelleAndGhandi'
        });
        const authReq = req.clone({headers: newHeaders});
        return next.handle(authReq).pipe(
            tap((event: HttpEvent<any>) => {},
            (err:any) => {
                if(err instanceof HttpErrorResponse){
                    this.errorHandler(err);
                }
            })
        );
    }

    errorHandler(err: HttpErrorResponse){
        switch (err.status) {
            case 400:
                break;
            case 402:
                // this.toastrService.error('Logging session expired!');
                break;
                case 401:
                break;
            case 500:
                // this.toastrService.error('Server error');
                break;
            default:
        }
    }

}