import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestConfig } from './rest.config'
import { User } from '../models/user/user';

@Injectable()
export class UserService {
  constructor(private httpClient: HttpClient) {}
  createUser(user: User): Observable<User> {
  return this.httpClient.post<User>(`${RestConfig.apiUrl}/users`, user);
  }

  confirmUserEmail(user: User): Observable<User>{
    return this.httpClient.post<User>(`${RestConfig.apiUrl}/users/confirm-email`, user);
  }

  getUser(id: number): Observable<User>{
    return this.httpClient.get<User>(`${RestConfig.apiUrl}/users/${id}`);
  }

  getUsers(): Observable<User[]>{
    return this.httpClient.get<User[]>(`${RestConfig.apiUrl}/users`);
  }

  inviteUser(user: User): Observable<User>{
    return this.httpClient.put<User>(`${RestConfig.apiUrl}/users/${user.id}/invite`, user);
  }

  rejectInvitation(user: User) {
    return this.httpClient.put<User>(`${RestConfig.apiUrl}/users/${user.id}/reject`, user);
  }
}
