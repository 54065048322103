<div class="m-5 page-height" *ngIf="loading"> loading... </div>
<div *ngIf="!loading">
  <body data-spy="scroll">
    <!-- Start Home Section -->
    <section id="home">
      <!-- Start Image Slider -->
      <ngb-carousel
        [interval]="9000"
        [showNavigationArrows]="true"
        [showNavigationIndicators]="false"
      >
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              class="caroussel-animation caroussel-img"
              [src]="'assets/pictures/w1.jpg'"
              alt="Random first slide"
            />
          </div>
          <div>
            <h1 class="carousel-caption"> We are getting married</h1>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              class="caroussel-animation caroussel-img"
              [src]="'assets/pictures/w2.jpg'"
              alt="Random second slide"
            />
          </div>
          <div>
            <h1 class="carousel-caption">Save the Date: "14 may 2021"</h1>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              class="caroussel-animation caroussel-img"
              [src]="'assets/pictures/ca.jpg'"
              alt="Random third slide"
            />
          </div>
          <div>
            <h1 class="carousel-caption">Be part of us on 14 may 2021</h1>
          </div>
        </ng-template>
      </ngb-carousel>
      <!-- End Image Slider -->
    </section>
    <!-- End Home Section -->
  
    <!-- Start Events Section -->
    <section id="eventsDetails" class="bg-color">
      <div mt-3>
        <h2 class="section-title text-nowrap"> Wedding Details & Schedule </h2>
        <div>
          <!-- Countdown Start -->
            <!-- Large Screen -->
            <!-- <div class="d-md-flex d-none my-5 countdown countdown-lg">
              <div id="day" class="text-center mr-3"><span *ngIf="days < 10">0</span>{{ days }}</div>
              <div id="hour" class="text-center mr-3"><span *ngIf="hours < 10">0</span>{{ hours }}</div>
              <div id="minute" class="text-center mr-3">
                <span *ngIf="minutes < 10">0</span>{{ minutes }}
              </div>
              <div id="second" class="text-center mr-3">
                <span *ngIf="seconds < 10">0</span>{{ seconds }}
              </div> -->
            <div class="d-md-flex d-none my-5 countdown countdown-lg">
              <div id="day" class="text-center mr-3"><span >00</span></div>
              <div id="hour" class="text-center mr-3"><span >00</span></div>
              <div id="minute" class="text-center mr-3">
                <span>00</span>
              </div>
              <div id="second" class="text-center mr-3">
                <span>00</span>
              </div>
            </div>
            <!-- Small Screen -->
            <div class="d-md-none my-5 mx-4 countdown countdown-md d-flex">
              <div id="day" class="text-center">
                <span>00</span>
              </div>
              <div id="hour" class="text-center"><span>00</span></div>
              <div id="minute" class="text-center">
                <span>00</span>
              </div>
              <div id="second" class="text-center">
                <span>00</span>
              </div>
              <!-- <div id="day" class="text-center">
                <span *ngIf="days < 10">0</span>
                <span>{{ days }}</span>
              </div>
              <div id="hour" class="text-center"><span *ngIf="hours < 10">0</span>{{ hours }}</div>
              <div id="minute" class="text-center">
                <span *ngIf="minutes < 10">0</span>{{ minutes }}
              </div>
              <div id="second" class="text-center">
                <span *ngIf="seconds < 10">0</span>{{ seconds }}
              </div> -->
            </div>
            <!-- Countdown End -->
        </div>
  
        <div class="card-event-wrapper my-5">
          <div class="card card-event">
            <img
              class="card-event-img"
              src="assets/pictures/church_event.jpg"
              alt=""
            />
            <div class="card-body event">
              <h5 class="card-title text-center">Traditional marriage</h5>
              <p class="card-text text-center">
                On 08.05.2021 at 06pm <br />
                Location: Cité des palmiers, Douala
              </p>
            </div>
          </div>
          <div class="card card-event">
            <img
              class="card-event-img"
              src="assets/pictures/wine_event.jpeg"
              alt=""
            />
            <div class="card-body event">
              <h5 class="card-title text-center"> Civil marriage </h5>
              <p class="card-text text-center">
                On 14.05.2021 at 09am <br />
                Location: Hotel de ville de Yaounde
              </p>
            </div>
          </div>
          <div class="card card-event">
            <img
              class="card-event-img"
              src="assets/pictures/traditional_event.jpeg"
              alt=""
            />
            <div class="card-body event">
              <h5 class="card-title text-center"> Church </h5>
              <p class="card-text text-center">
                On 14.05.2021 at 03pm <br />
                Location: Basilique Marie-Reine-des-Apôtres, Yaounde
              </p>'
            </div>
          </div>
          <div class="card card-event">
            <img
              class="card-event-img"
              src="assets/pictures/down_hall_event.jpeg"
              alt=""
            />
            <div class="card-body event">
              <h5 class="card-title text-center"> Wedding night </h5>
              <p class="card-text text-center">
                On 14.05.2021 at 07pm <br />
                Location: Espace Nana Soleil (Etoug-ébé), Yaounde
              </p>
            </div>
          </div>
        </div>

        <div class="my-5 text-center">
          <button [ngClass]="{'bounce': bouncing}"
            class="btn register-color mt-5 " (click)="openParticipateModal()">
            <h5> Register NOW </h5>
          </button>
        </div>
  
        <div class="text-center mt-3">
          <img class="mb-3 flower" src="assets/pictures/green-flower.png" alt="">
        </div>
      </div>
    </section>
    <!-- End Events Section -->
  
    <!-- Start aboutUs Section -->
    <section id="aboutUs" class="bg-color">
      <div class="aboutus-transition">
        <h1 class="text-nowrap ad-h1">Meet the happy couple</h1>
      </div>
  
      <div>
        <h2 class="section-title">About us</h2>
      </div>
  
      <!-- <div class="row"> -->
      <div class="card-history-wrapper mt-4">
        <div class="card card-history ml-auto mr-auto bg-color">
          <img
            class="card-history-img"
            src="assets/pictures/ghandi.jpg"
            alt=""
            style="object-fit: cover; object-position: 50% 30%;"
          />
          <div class="card-body card-body-history ml-auto mr-auto">
            <h4 class="card-title text-center">Ghandi Dissing</h4>
            <!-- <p class="card-text text-center">
              Pellentesque habitant morbi tristique senectus et netus et malesuada
              fames ac turpis egestas. Fusce elementum elementum erat a blandit.
            </p> -->
          </div>
        </div>
  
        <div class="card card-history ml-auto mr-auto bg-color">
          <img
            class="card-history-img"
            src="assets/pictures/joelle2.jpg"
            alt=""
            style="object-fit: cover; object-position: 50% 59%;"
          />
          <div class="card-body card-body-history ml-auto mr-auto">
            <h4 class="card-title text-center">Joelle Kemajou</h4>
            <!-- <p class="card-text text-center">
              Pellentesque habitant morbi tristique senectus et netus et malesuada
              fames ac turpis egestas. Fusce elementum elementum erat a blandit.
            </p> -->
          </div>
        </div>
      </div>
  
      <div class="bg-color pb-3">
        <div> <h1 class="our-story tangerine"> Our story</h1> </div>
        <div class="timeline pt-5">
          <div class="container bg-color container-left">
            <div>
              <img class="image how-we-met" src="assets/pictures/how_we_met.jpeg" alt="">
            </div>
            <div class="content card">
              <h1 class="text-center tangerine mb-0">How we met </h1>
              <div class="card-boddy">
                We met on a Monday morning after classes (2012). We attended the same German language institute in Yaounde.
                I had just got into my taxi, when she stopped the taxi! Came in..... THE REST is history. <br>
                "Best friends from that day on ❤️" <br> <br> <i> Ghandi </i>
              </div>
            </div>
          </div>
    
          <div class="container bg-color container-right">
            <div>
              <img class="image know-each-other" src="assets/pictures/history1.jpg" alt="">
            </div>
            <div class="content card">
              <h1 class="text-center tangerine">We got to know each other </h1>
              <div class="card-boddy">
                After the short and intensive conversation we had in the taxi, and with a lot of insistence coming from Ghandi 😅, we started to communicate very timidly (two/three times weekly). I was very cautious because of our age difference at that time (I was 17 and he was 23 😅)
                Then one bright morning in June 2013 I started to feel a facial paralysis, I waved to my relatives, but no one looked at it as something serious. I was nevertheless sure it was, so I called up Ghandi. To be fair…..,I was very surprised by his commitment and his determination to find me a neurologist in the whole city of Yaoundé (Cameroon).
                Unfortunately, that day we didn't find a neurologist, but I found a man who could sacrifice himself for my health 😍 and also protect me.
                Due to my illness I had to stop classes at school. Ghandi came to see me every night (Because I could only go out in the evenings, I was sensitive to the sun) and he always brought me little gifts and told me all the stories happening at school to bring a little smile on my face.
                This disease destroyed me physically and also psychologically, but I always had my darling present to tell me that I was the most beautiful (what a cutie).
                Afterwards I started to recover from it I resumed the language courses. We were together all the time and we developed a good complicity. This was the beginning of a beautiful love relationship 🥰. <br><br> <i> Joelle </i>
              </div>
            </div>
          </div>
    
          <div class="container container-left">
            <div>
              <img class="image fall-in-love" src="assets/pictures/w3.jpg" alt="">
            </div>
            <div class="content card">
              <h1 class="text-center tangerine"> We fell in love </h1>
              <div class="card-boddy">
                The same year I traveled to Germany. Ghandi followed me the year after!
                We were a young couple in a new environment, a young couple confronted with several difficult situations such as: the cultural difference, the difficulties of the German language, our studies, our finances and moreover we had to manage the administrative side ourselves. In short, we were becoming adults.
                This new difficult period strengthened us a lot. We supported each other a lot and we started to function as a team where each one could bring a little more to the relationship according to his abilities.
                On the other hand, I fell under the charm of this very pious man, who, when faced with good or bad times, gave everything to God.
                <br><br> <i> Joelle </i>
              </div>
            </div>
          </div>
    
          <div class="container container-right">
            <div>
              <img class="image we-decided-married" src="assets/pictures/we_decided_married.jpeg" alt="">
            </div>
            <div class="content card">
              <h1 class="text-center tangerine"> We decided to get married </h1>
              <div class="card-boddy">
                  After about 5 years of intensive relationship, I thought I had seen enough, my fiancée and I had gone through beautiful and difficult situations/moments. I became more and more convinced of the fact that She is really what I need/deserve as my wife.
                  <i> She is a woman full of qualities, she is pious, hardworking, very beautiful.</i>
                  She gives me a lot of advice and the strength to evolve as an accomplished man in my life.
                  Joëlle, Each time you come across this note, remember I IOVE you my princess.
                  <br><br> <i>Ghandi</i>
              </div>
            </div>
          </div>
    
          <div class="container container-left">
            <div>
              <img class="image now" src="assets/pictures/w4.jpg" alt="">
            </div>
            <div class="content card mb-0 ">
              <h1 class="text-center tangerine"> Now </h1>
              <div class="card-boddy">
                After the marriage proposal in 2017 (Yeah! it has been a long time in the making 😊).
                We went through some great moments in our lives.
                We also received a lot of advice from our respective families to prepare us psychologically and religiously for this beautiful adventure.
                The wedding ceremony was initially planned on 10.10.2020 in Cameroon. Unfortunately, due to the COVID-19's pendency, we decided to postpone the wedding to 14.05.2021.
                We are happy to invite you to our wedding ceremony. <br><br> <i>Joelle & Ghandi</i>
              </div>
            </div>
          </div>
          <div>
        </div>
        </div>
      </div>
    </section>
    <!-- End aboutUs Section -->
  
    <!-- Start Wishes Section -->
    <!-- <section id="guestBook" class="my-5 bg-color">
      <div class="wishes-title">
        <h1 class="text-center banner-title">Love is so beautiful</h1>
      </div>
      <div class="text-center subtitles my-5">
        <h2 class="subtitles text-center">Guest Book </h2>
      </div>
  
      <div class="card-whishes-wrapper">
        <div class="card card-whishes ml-auto mr-auto mb-3">
          <div class="card-body-whishes p-3">
            <div class="row">
              <span class="col"><strong>Kouam</strong></span>
              <span class="col text-nowrap text-right">
                <small>wrote on 20/06/2020</small>
              </span>
            </div>
            <hr />
            <p class="card-text">
              Pellentesque habitant morbi tristique senectus et netus et malesuada
              fames ac turpis egestas. Fusce elementum elementum erat a blandit.
            </p>
          </div>
        </div>
  
        <div class="card card-whishes ml-auto mr-auto mb-3">
          <div class="card-body-whishes p-3">
            <div class="row">
              <span class="col"><strong>Kouam</strong></span>
              <span class="col text-nowrap text-right">
                <small>wrote on 20/06/2020</small>
              </span>
            </div>
            <hr />
            <p class="card-text">
              Pellentesque habitant morbi tristique senectus et netus et malesuada
              fames ac turpis egestas. Fusce elementum elementum erat a blandit.
            </p>
          </div>
        </div>
  
        <div class="card card-whishes ml-auto mr-auto mb-3">
          <div class="card-body-whishes p-3">
            <div class="row">
              <span class="col"><strong>Kouam</strong></span>
              <span class="col text-nowrap text-right">
                <small>wrote on 20/06/2020</small>
              </span>
            </div>
            <hr />
            <p class="card-text">
              Pellentesque habitant morbi tristique senectus et netus et malesuada
              fames ac turpis egestas. Fusce elementum elementum erat a blandit.
            </p>
          </div>
        </div>
      </div>
  
      <div class="text-center">
        <button class="btn btn-primary" (click)="goToGuestBook()">See more comment</button>
      </div>
    </section> -->
    <!-- End Wishes Section -->
  
    <!-- Start Gifts Section Section -->
    <section id="gifts" class="bg-color">
      <div class="gift-transition">
        <h1 class="text-nowrap">Love is so beautiful</h1>
      </div>

      <div >
        <h2 class="section-title">Our Gift registry</h2>
      </div>

      <div class="gift-wrapper pb-5">
        <div class=" ml-auto mr-auto bg-color">
          <div class="my-5 text-center">
            <button
              class="btn register-color mb-3 bounce ">
              <a style="color: white;" href="https://pauleangels.com/collections/joelle-kemajou-et-ghandi-dissing-mariage" target="_blank">
                <h5> Wedding gifts </h5>
              </a>
            </button>
            <p>
              See our gift registry from Paule & Angels
            </p>
            <div>
              <a href="https://pauleangels.com/collections/joelle-kemajou-et-ghandi-dissing-mariage" target="_blank">
                <img class="paule-and-angels-logo" src="assets/pictures/paule_and_angels.png" alt="">
              </a>
            </div>
          </div>
          <div class="mt-3 text-center">
          </div>
        </div>

        <div class=" ml-auto mr-auto bg-color mt-5">
          <!-- <div>
            <form action="https://www.paypal.com/donate" method="post" target="_top">
              <input type="hidden" name="business" value="joellekemajou@yahoo.fr" />
              <input type="hidden" name="item_name" value="Joelle & Ghandi Wedding" />
              <input type="hidden" name="currency_code" value="EUR" />
              <input type="image" src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_DE/i/scr/pixel.gif" width="1" height="1" />
              </form>
          </div> -->
          <div *ngIf="wantToSentPaypal" class="my-3">
            <input  [(ngModel)]="amount" type="number" placeholder="Amount in EUR">
            <button class="ml-3" (click)="checkAmount()"> ok </button>
            <button class="ml-3" (click)="cancelPayment()"> cancel </button>
          </div>
          <!-- <button
            class="btn register-color bounce ml-2">
            <h5>
              <a style="color: white;" href="https://www.paypal.com/donate?business=joellekemajou%40yahoo.fr&item_name=Joelle+%26+Ghandi+Wedding&currency_code=EUR" target="_blank">
                Donate (€)
              </a>
            </h5>
          </button> -->
          <button *ngIf="!wantToSentPaypal" (click)="praparePayment()"
            class="btn register-color bounce ml-2">
            <h5>
                Donate (€)
            </h5>
          </button>
          <div class="mt-3">
            <span class="text-center mt-3">
              <p>
                Wedding gift via paypal transfer
              </p>
            </span>
          </div>
          <div  class="">
            <!-- <div >
                <a href="https://www.paypal.com/donate?business=joellekemajou%40yahoo.fr&item_name=Joelle+%26+Ghandi+Wedding&currency_code=EUR" target="_blank" rel="noopener noreferrer">
                  <img class="paypal-logo" src="assets/pictures/paypal-logo.png" alt="">
                </a>
              </div> -->
            <div *ngIf="!wantToSentPaypal">
              <img (click)="praparePayment()" class="paypal-logo" src="assets/pictures/paypal-logo.png" alt="">
            </div>
            <div *ngIf="wantToSentPaypal">
              <div id="paypal-checkout-btn"> </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Gifts Section Section -->
  </body> 
</div>
