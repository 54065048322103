<div class="mt-5 content-size page-height">
    <div class="text-center mt-5 pt-5">
        <h1 class="tangerine">Administration</h1>
    </div>
    <div *ngIf="loading" class="text-center">
        <span>loading...!</span>
    </div>
    <div *ngIf="!loading" class="card mt-3">
        <table class="content-table mx-auto">
            <thead>
                <th style="width: 100px;">Status</th>
                <th>Name</th>
                <th>Registred at</th>
                <th>Email</th>
                <th>Events</th>
                <th>Phone number</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                    <td>
                        <i *ngIf="user.status ==='INVITED'"
                        ngbTooltip="Invited"
                        class="icon ion-md-checkmark-circle" style="color: green; font-size: 1.3rem;"></i>

                        <i *ngIf="user.status ==='PENDING'"
                        ngbTooltip="Email not confirmed"
                        class="icon ion-md-alert" style="color: orange; font-size: 1.3rem;"></i>

                        <i *ngIf="user.status ==='REGISTERED'"
                        ngbTooltip="Pending"
                        class="icon ion-md-alert" style="color: yellow; font-size: 1.3rem;"></i>

                        <i *ngIf="user.status ==='REJECTED'"
                        ngbTooltip="Rejected"
                        class="icon icon ion-md-close-circle" style="color: red; font-size: 1.3rem;"></i>
                    </td>
                    <td>{{user.firstname}} {{user.lastname}}</td>
                    <td>{{user.createdAt | date: 'short' }}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.selectedEvents}}</td>
                    <td *ngIf="user.phoneNr">{{user.phoneNr}}</td>
                    <td *ngIf="!user.phoneNr">-</td>
                    <td>
                        <div>
                            <button  class="btn btn-secondary-outline"> <i  (click)="inviteUser(user)" class="icon ion-md-checkmark-circle" ngbTooltip="Invite Person" style="color:green; font-size: 1.3rem;"></i></button>
                            <button *ngIf="user.status !='REJECTED'" class="ml-2 btn btn-secondary-outline" ><i (click)="rejectInvitation(user)" class="icon ion-md-close-circle" ngbTooltip="Reject Person" style="color: red; font-size: 1.3rem;"></i> </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
