import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models/user/user';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  users: User[];
  loading = true;

  constructor(
    private userService: UserService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(): void {
    this.userService.getUsers().subscribe((users: User[]) => {
      this.users = users;
      this.loading = false;
    });
  }

  inviteUser(user: User): void {
    this.loading = true;
    this.userService.inviteUser(user).subscribe(() => {
      this.toastr.success(user.lastname + ' has been invited successfully!');
      this.getUsers();
    });
  }

  rejectInvitation(user: User): void {
    this.loading = true;
    this.userService.rejectInvitation(user).subscribe(() => {
      this.toastr.success(user.lastname + 'has not been invited!');
      this.getUsers();
    });
  }

}
