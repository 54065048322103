import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthUser } from './shared/models/user/auth-user';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'wedding.app';
  isCollapsed = true;
  isLoggedIn = false;
  authUser: AuthUser;

  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    // Check Login and update
    this.authService.userLoggedInObserver.subscribe(() => {
      this.getAuthUser();
    });
  }

  ngOnInit(): void {
    this.getAuthUser();
  }

  ngAfterViewInit(): void {
    var sample = document.getElementsByTagName("audio");
    sample.item(0).volume = 0.02;
  }

  getAuthUser(): void {
    this.authUser = this.authService.getAuthUser();
    this.isLoggedIn = true;
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  logout(): void {
    this.authService.logout();
    this.authUser = null;
    this.isLoggedIn = false;
    this.router.navigate(['/login']);
    this.toastrService.success('Logout successful!');
  }
}
