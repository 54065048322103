<div class="pt-10rem mt-47px page-height bg-color">
    <div class="card mx-auto">
        <div class="card-header">
            <h3>Login</h3>
        </div>
        <div class="card-body">
            <form [formGroup]="loginForm">
                <!-- <div class="form-group">
                    <label for="">username</label>
                    <input
                    [ngClass]="{'is-invalid': loginForm.get('username').hasError('required') && (loginForm.get('username').touched || submitted)}"
                    type="text" formControlName="username" class="form-control" placeholder="Username">
                    <div *ngIf="loginForm.get('username').hasError('required') && (loginForm.get('username').touched || submitted)" class="text-danger"> <small>This field is required</small></div>
                </div> -->
                <div class="form-group">
                    <label for="">Password</label>
                    <input
                    [ngClass]="{'is-invalid': loginForm.get('password').hasError('required') && (loginForm.get('password').touched || submitted)}"
                    type="password" formControlName="password" class="form-control" placeholder="Password" required="required">
                    <div *ngIf="loginForm.get('password').hasError('required') && (loginForm.get('password').touched || submitted)" class="text-danger"> <small>This field is required</small></div>
                </div>
                <div class="px-2">
                    <button class="float-right btn btn-secondary btn-sm" (click)="login()">LOGIN</button>
                </div>
                </form>
        </div>
    </div>
</div>
