import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(){
    console.log('check auth');
    if(this.authService.isLoggedIn()){
      return true;
    } else {
      this.router.navigate(['/login']);
    }
  }
  
}
