import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RestConfig } from './rest.config';
import { User } from '../models/user/user';
import { AuthUser } from '../models/user/auth-user';
import { UserService } from './user.service';

@Injectable()
export class AuthService {

    private userLoggedIn: Subject<AuthUser> = new Subject();

    userLoggedInObserver: Observable<AuthUser> = this.userLoggedIn.asObservable();

    private authUser = null;

    constructor(
        private httpClient: HttpClient,
        private userService: UserService
        ) {}

    public getAuthUser() {
        return this.authUser;
    }

    public login(loginData: any): Observable<AuthUser>{
        return this.httpClient.post<AuthUser>(`${RestConfig.apiUrl}/auth/login`, {username: loginData.username, password: loginData.password});
    }

    public logout(){
        this.authUser = null;
        localStorage.clear();
        // return this.httpClient.post<void>(`${RestConfig.apiUrl}/auth/login`, {username: loginData.username, password: loginData.password});
    }

    setAuthUser(authUser: AuthUser){
        this.authUser = authUser;
        this.userLoggedIn.next(authUser);
    }

    public doLogin(authUser: AuthUser){
        this.setAuthUser(authUser)
        localStorage.setItem('auth-token', this.authUser.authToken);
        localStorage.setItem('id', this.authUser.id);
        localStorage.setItem('role', this.authUser.role)
    }

    isLoggedIn(): boolean {
        if(!this.authUser && !!localStorage.getItem('auth-token') && !!localStorage.getItem('id')){
            this.authUser = new AuthUser();
            const id = localStorage.getItem('id');
            this.authUser.id = id;
            this.authUser.authToken = localStorage.getItem('auth-token');
            this.authUser.role = localStorage.getItem('role');
            this.userService.getUser(parseInt(id, 10)).subscribe((user) => {
                this.setAuthUser(this.authUser);
                return true;
            });
        }
        return !!localStorage.getItem('auth-token') && !!localStorage.getItem('id');
    }

    getToken(): string {
        return localStorage.getItem('auth-token');
    }
}